import React from "react";
import { Route, Switch } from "react-router-dom";

//  Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicetwo = React.lazy(() => import("./components/pages/Servicetwo"));
const Servicedetails = React.lazy(() =>
  import("./components/pages/Servicedetails")
);

const Casestudies = React.lazy(() => import("./components/pages/Casestudies"));
const Casestudiestwo = React.lazy(() =>
  import("./components/pages/Casestudiestwo")
);
const Casestudy = React.lazy(() => import("./components/pages/Casestudy"));
const Sectors = React.lazy(() => import("./components/pages/Sectors"));
const Breakdown = React.lazy(() => import("./components/pages/Breakdown"));
const Installation = React.lazy(() =>
  import("./components/pages/Installation")
);
const LaserAlignment = React.lazy(() =>
  import("./components/pages/LaserAlignment")
);
const Maintenance = React.lazy(() => import("./components/pages/Maintenance"));
const LabourSupply = React.lazy(() =>
  import("./components/pages/LabourSupply")
);

const Relocation = React.lazy(() => import("./components/pages/Relocation"));
const Fabrication = React.lazy(() => import("./components/pages/Fabrication"));

const Careers = React.lazy(() => import("./components/pages/Careers"));
const Wepa = React.lazy(() => import("./components/pages/Wepa"));
const Brewpack = React.lazy(() => import("./components/pages/Brewpack"));
const Timet = React.lazy(() => import("./components/pages/Timet"));

function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/faq" component={Faq} />
      <Route path="/maintenance" component={Maintenance} />
      <Route path="/installation" component={Installation} />
      <Route path="/laboursupply" component={LabourSupply} />
      <Route path="/relocation" component={Relocation} />
      <Route path="/fabrication" component={Fabrication} />
      <Route path="/breakdown" component={Breakdown} />
      <Route path="/laseralignment" component={LaserAlignment} />
      <Route path="/services" component={Service} />
      <Route path="/services-v2" component={Servicetwo} />
      <Route path="/service-details" component={Servicedetails} />
      <Route path="/case-studies" component={Casestudies} />
      <Route path="/case-studies-v2" component={Casestudiestwo} />
      <Route path="/case-study" component={Casestudy} />

      <Route path="/projectwork" component="#" />

      <Route path="/shutdown" component="#" />

      <Route path="/daycover" component="#" />
      <Route path="/shiftcover" component="#" />
      <Route path="/plannedshutdowns" component="#" />
      <Route path="/turnkey" component="#" />
      <Route path="/oem" component="#" />

      <Route path="/largerelocation" component="#" />
      <Route path="/smallrelocation" component="#" />

      <Route path="/offset" component="#" />
      <Route path="/straightline" component="#" />
      <Route path="/motors" component="#" />
      <Route path="/sectors" component={Sectors} />

      <Route path="/careers" component={Careers} />
      <Route path="/wepa-case-study" component={Wepa} />
      <Route path="/brewpack-case-study" component={Brewpack} />
      <Route path="/timet-case-study" component={Timet} />
    </Switch>
  );
}

export default AppRoutes;

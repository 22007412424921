// import classNames from "classnames";
import React from "react";
import classes from "./assets/css/loading.module.css";
import Cog from "./assets/img/Cog.png";

const Loading = () => (
  <div className="classes.loadingDiv">
    <img src={Cog} className={classes.cogimg} alt="Loading" />
  </div>
);

export default Loading;

// className={classes.loadingDiv}
